import React, { Fragment } from 'react'
import style from './button.mod.scss'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

const ButtonContent = ({ children }) => (
  <Fragment>
    <span className={style.button__text}>{children}</span>
  </Fragment>
)

const Button = ({
  children,
  gaTarget,
  disabled = false,
  onClick,
  size = 'medium',
  title,
  to = '/',
  type = null,
  unstyled = false,
  modifiers = [],
  centre,
  download,
  ...props
}) => {
  const data = useStaticQuery(graphql`
    query SiteUrlQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  centre && modifiers.push(`${style.button}--centre`)

  const { siteUrl } = data.site.siteMetadata

  const externalLink =
    to.search(/^tel:/g) > -1 ||
    to.search(/^mailto:/g) > -1 ||
    (to.search(/http/g) > -1 && to.search(siteUrl) === -1)

  const config = {
    className: unstyled
      ? [style['button--unstyled'], ...modifiers].join(' ')
      : [style.button, ...modifiers].join(' '),
    onClick
  }

  // Strip the siteURL from the to field to create a relative path for <Link />
  if (!externalLink && !type && to.search(siteUrl) === -1)
    to.replace(siteUrl, '')

  // Set ariaLabel for <button />
  if (title && type) config.ariaLabel = title

  // Set title for <a /> and <Link />
  if (title && !type) config.title = title

  if (gaTarget) config['data-ga-target'] = gaTarget

  if (download) config.download = download

  if (externalLink) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...config}>
        <ButtonContent children={children} />
      </a>
    )
  } else if (type) {
    return (
      <button type={type} disabled={disabled} {...config}>
        <ButtonContent children={children} />
      </button>
    )
  } else {
    return (
      <Link to={to} {...config} {...props}>
        <ButtonContent children={children} />
      </Link>
    )
  }
}

export default Button

Button.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func
}

export const query = graphql`
  fragment Link on DatoCmsLink {
    id
    displayText
    url
    page {
      ... on DatoCmsPage {
        id
        path
      }
    }
    __typename
  }

  fragment Button on DatoCmsButton {
    id
    paddingTop
    paddingBottom
    centre
    model {
      apiKey
    }
    link {
      ...Link
    }
    __typename
  }
`

import React from 'react'
import { graphql } from 'gatsby'
import style from './content.mod.scss'
import { Button } from '@components'
import cn from 'classnames'

const Content = ({ content, link, centreText }) => {
  const classes = cn(style['content'], {
    [style[`content--center`]]: centreText
  })

  return (
    <div className={classes}>
      <div className={style.content__wrapper}>
        <div
          className={style.content__body}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      {(link?.page?.path || link?.url) && (
        <div className={style.content__button}>
          <Button centered to={link?.page?.path || link?.url}>
            {link.displayText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Content

export const query = graphql`
  fragment Content on DatoCmsContent {
    id
    paddingTop
    paddingBottom
    content
    model {
      apiKey
    }
    centreText
    __typename
  }
`

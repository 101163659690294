import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import style from './image.mod.scss'

const Image = ({ image, portraitImage, portrait }) => {
  const { gatsbyImageData, alt } = portrait ? portraitImage : image

  return (
    <div className={style.image}>
      {image && (
        <div className={style.image__wrap}>
          <GatsbyImage image={gatsbyImageData} alt={alt} />
        </div>
      )}
    </div>
  )
}

export default Image

export const query = graphql`
  fragment Image on DatoCmsImage {
    id
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "431", fit: "crop", w: "768", q: 60 }
      )
      alt
    }
    portraitImage: image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "1087", fit: "crop", w: "768", q: 60 }
      )
      alt
    }
    model {
      apiKey
    }
    paddingBottom
    paddingTop
    portrait
    __typename
  }
`

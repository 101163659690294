import React from 'react'
import { graphql } from 'gatsby'
import cn from 'classnames'
import style from './image-with-content.mod.scss'
import { Button, Content, Image } from '@components'

const ImageWithContent = ({ button, content, image, reverse }) => {
  const classes = cn(style['image-with-content'], {
    [style[`image-with-content--reverse`]]: reverse
  })

  return (
    <div className={classes}>
      <div className={style[`image-with-content__image`]}>
        <Image image={image} />
      </div>
      <div className={style[`image-with-content__content`]}>
        <Content linkAlignment="left" content={content} />
        {(button?.link?.path || button?.url) && (
          <Button
            to={button?.link?.path || button.url}
            children={button?.displayText}
          />
        )}
      </div>
    </div>
  )
}

export default ImageWithContent

export const query = graphql`
  fragment ImageWithContent on DatoCmsImageWithContent {
    button {
      ...Link
    }
    content
    id
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "478", fit: "crop", w: "720", q: 60 }
      )
      alt
    }
    reverse
    model {
      apiKey
    }
    paddingBottom
    paddingTop
    __typename
  }
`

import React from 'react'
import { graphql } from 'gatsby'
import style from './subheading.mod.scss'
import cn from 'classnames'

const Subheading = ({ subheading, centreText }) => {
  const classes = cn(style['subheading'], {
    [style[`subheading--center`]]: centreText
  })

  return (
    <div className={classes}>
      <p>{subheading}</p>
    </div>
  )
}

export default Subheading

export const query = graphql`
  fragment Subheading on DatoCmsSubheading {
    id
    paddingTop
    paddingBottom
    subheading
    model {
      apiKey
    }
    centreText
    __typename
  }
`

import React from 'react'
import style from './layout.mod.scss'
import PropTypes from 'prop-types'
import { JsonLd } from 'react-schemaorg'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { useStaticQuery, graphql } from 'gatsby'

const Layout = ({ children }) => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          lang
          siteUrl
          facebook
          instagram
        }
      }
    }
  `)

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: site.siteMetadata.title,
    description: site.siteMetadata.description,
    email: site.siteMetadata.email,
    contactPoint: [
      {
        '@type': 'ContactPoint',
        email: site.siteMetadata.email,
        contactType: 'customer service'
      }
    ],
    url: site.siteMetadata.siteUrl,
    logo: site.siteMetadata.logo,
    sameAs: [site.siteMetadata.facebook, site.siteMetadata.instagram]
  }

  return (
    <>
      <HelmetDatoCms defer={false}>
        <meta name="format-detection" content="telephone=no" />
        <html lang="en" />
      </HelmetDatoCms>
      <div id="layout" className={style.layout}>
        <div className={style.layout__content}>
          <main>{children}</main>
        </div>
        <JsonLd item={schema} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout

import React from 'react'
import style from './badge-grid.mod.scss'
import { graphql } from 'gatsby'
import { Container, FeatureItem } from '@components'

const BadgeGrid = ({ badges, id }) => {
  return (
    <div className={[style['badge-grid']]}>
      <Container>
        <div className={[style['badge-grid__grid']]}>
          {badges.map((badge, i) => {
            return (
              <div
                key={`badge-grid-${id}-${badge.id}-${i}`}
                className={[style['badge-grid__item']]}
              >
                <FeatureItem
                  image={badge.image}
                  text={badge.caption}
                  assetForDownload={badge.assetForDownload}
                />
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default BadgeGrid

export const query = graphql`
  fragment BadgeGrid on DatoCmsBadgeGrid {
    id
    paddingTop
    paddingBottom
    badges {
      id
      caption
      assetForDownload {
        url
      }
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "500", fit: "crop", w: "400" }
        )
        alt
      }
    }
    __typename
  }
`

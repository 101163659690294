import React from 'react'
import style from './feature-item.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Button } from '@components'

const FeatureItem = ({ image, text, assetForDownload }) => {
  return (
    <div className={style[`feature-item`]}>
      <div className={style[`feature-item__image`]}>
        <GatsbyImage
          className={style[`feature-item__gatsby_image`]}
          image={image.gatsbyImageData}
          alt={image.alt}
        />
      </div>
      <div
        className={style[`feature-item__text`]}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <div className={style[`feature-item__button`]}>
        <Button to={assetForDownload.url} download>
          {'Download'}
        </Button>
      </div>
    </div>
  )
}

export default FeatureItem

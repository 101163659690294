import React from 'react'
import { graphql } from 'gatsby'
import style from './hero.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

const Hero = ({ image }) => {
  return (
    <div className={style.hero}>
      <GatsbyImage
        image={image.gatsbyImageData}
        alt={image.alt}
        loading={'eager'}
        className={style.hero__image}
      />
    </div>
  )
}

export default Hero

export const query = graphql`
  fragment Hero on DatoCmsHero {
    id
    heroImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "550", fit: "crop", w: "1170" }
      )
      alt
    }
    model {
      apiKey
    }
    __typename
  }
`

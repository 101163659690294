import React from 'react'
import { graphql } from 'gatsby'
import {
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import ProgressProvider from '../helpers/progressProvider'
import style from './vaccine-progress.mod.scss'

const VaccineProgress = ({ currentPercentage }) => {
  return (
    <div className={style['vaccine-progress']}>
      <div className={style['vaccine-progress__inner']}>
        <ProgressProvider valueStart={0} valueEnd={currentPercentage}>
          {value => (
            <CircularProgressbarWithChildren
              value={value}
              background
              backgroundPadding={-5}
              styles={buildStyles({
                trailColor: 'transparent',
                pathTransitionDuration: 1.5
              })}
            >
              <h3>{`${value}%`}</h3>
            </CircularProgressbarWithChildren>
          )}
        </ProgressProvider>
      </div>
    </div>
  )
}

export default VaccineProgress

export const query = graphql`
  fragment VaccineProgress on DatoCmsVaccineProgress {
    id
    currentPercentage
    paddingTop
    paddingBottom
    model {
      apiKey
    }
    __typename
  }
`
